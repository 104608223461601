.animate__delay-half {
  -webkit-animation-delay: calc(1s * 0.5);
  animation-delay: calc(1s * 0.5);
  -webkit-animation-delay: calc(var(--animate-delay) * 0.5);
  animation-delay: calc(var(--animate-delay) * 0.5); }

.animate__delay-1-1 {
  -webkit-animation-delay: calc(1s * 1.1);
  animation-delay: calc(1s * 1.1);
  -webkit-animation-delay: calc(var(--animate-delay) * 1.1);
  animation-delay: calc(var(--animate-delay) * 1.1); }

.animate__delay-1-2 {
  -webkit-animation-delay: calc(1s * 1.2);
  animation-delay: calc(1s * 1.2);
  -webkit-animation-delay: calc(var(--animate-delay) * 1.2);
  animation-delay: calc(var(--animate-delay) * 1.2); }

.animate__delay-1-3 {
  -webkit-animation-delay: calc(1s * 1.3);
  animation-delay: calc(1s * 1.3);
  -webkit-animation-delay: calc(var(--animate-delay) * 1.3);
  animation-delay: calc(var(--animate-delay) * 1.3); }

.animate__delay-1-4 {
  -webkit-animation-delay: calc(1s * 1.4);
  animation-delay: calc(1s * 1.4);
  -webkit-animation-delay: calc(var(--animate-delay) * 1.4);
  animation-delay: calc(var(--animate-delay) * 1.4); }

.animate__delay-1half {
  -webkit-animation-delay: calc(1s * 1.5);
  animation-delay: calc(1s * 1.5);
  -webkit-animation-delay: calc(var(--animate-delay) * 1.5);
  animation-delay: calc(var(--animate-delay) * 1.5); }

.animate__delay-1-6 {
  -webkit-animation-delay: calc(1s * 1.6);
  animation-delay: calc(1s * 1.6);
  -webkit-animation-delay: calc(var(--animate-delay) * 1.6);
  animation-delay: calc(var(--animate-delay) * 1.6); }

.animate__delay-1-7 {
  -webkit-animation-delay: calc(1s * 1.7);
  animation-delay: calc(1s * 1.7);
  -webkit-animation-delay: calc(var(--animate-delay) * 1.7);
  animation-delay: calc(var(--animate-delay) * 1.7); }

.animate__animated.animate__delay-2half {
  -webkit-animation-delay: calc(1s * 2.5);
  animation-delay: calc(1s * 2.5);
  -webkit-animation-delay: calc(var(--animate-delay) * 2.5);
  animation-delay: calc(var(--animate-delay) * 2.5); }

.animate__animated.animate__delay-3half {
  -webkit-animation-delay: calc(1s * 3.5);
  animation-delay: calc(1s * 3.5);
  -webkit-animation-delay: calc(var(--animate-delay) * 3.5);
  animation-delay: calc(var(--animate-delay) * 3.5); }

.animate__animated.animate__delay-4half {
  -webkit-animation-delay: calc(1s * 4.5);
  animation-delay: calc(1s * 4.5);
  -webkit-animation-delay: calc(var(--animate-delay) * 4.5);
  animation-delay: calc(var(--animate-delay) * 4.5); }

.animate__animated.animate__delay-5half {
  -webkit-animation-delay: calc(1s * 5.5);
  animation-delay: calc(1s * 5.5);
  -webkit-animation-delay: calc(var(--animate-delay) * 5.5);
  animation-delay: calc(var(--animate-delay) * 5.5); }

.parallax {
  height: 500px;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important; }

html {
  height: auto;
  min-height: 100vh; }

.language {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  color: white;
  width: 100%;
  margin: auto; }
  .language a {
    width: 1000px;
    margin: auto;
    text-align: right;
    margin-top: 33px;
    font-size: 20px;
    display: block;
    color: white; }
    .language a:hover {
      text-decoration: none; }
    @media screen and (max-width: 1140px) {
      .language a {
        width: 85%; } }

section {
  padding: 130px; }
  @media screen and (max-width: 500px) {
    section {
      padding: 30px; } }

.h2, h2 {
  font-size: 4rem; }

.h3, h3 {
  font-size: 2.5rem; }

i {
  font-size: 80px; }

.display-4 {
  font-weight: 700;
  margin-top: 28px;
  position: relative; }
  .display-4:before {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #079dbd;
    content: '';
    top: -12px; }

.jumbotron {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  border-radius: 0;
  overflow: hidden; }
  @media screen and (max-width: 700px) {
    .jumbotron {
      height: auto; } }
  .jumbotron .header-content {
    position: absolute;
    width: 53%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 700px) {
      .jumbotron .header-content {
        position: relative;
        width: 90%;
        left: 5%;
        top: 5%;
        transform: translate(0%, 0%);
        margin: auto; }
        .jumbotron .header-content .display-4 {
          font-size: 2rem; } }
  .jumbotron .logo {
    width: 200px;
    position: absolute;
    right: -16px; }
  .jumbotron .e2-logo {
    position: absolute;
    top: 10px; }

.counter {
  font-size: 40px;
  font-weight: bold; }

.unit {
  font-size: 30px;
  font-weight: 500;
  display: inline-block;
  margin: 0 7px; }

.note {
  font-size: 14px;
  color: #b4ffff; }

.mainBg {
  background: #079dbd;
  color: white; }

.image-circled {
  background: #079dbd;
  border-radius: 50%;
  width: 108px;
  margin: 15px auto;
  padding: 20px;
  transition: all 0.4s;
  border: 4px solid #079dbd; }
  .image-circled .sa-green-icons {
    display: none; }
  .image-circled .sa-green-icons, .image-circled .sa-white-icons {
    transition: all 0.4s; }
  .image-circled:hover {
    transition: all 0.4s;
    background: #00b1d7;
    transform: scale(1.1);
    cursor: pointer; }
    .image-circled:hover .sa-green-icons {
      display: block;
      transition: all 0.4s; }
    .image-circled:hover .sa-white-icons {
      display: none;
      transition: all 0.4s; }
